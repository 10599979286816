
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import { numberWithCommas, numberWithCommasNull } from "../../../../helpers/numberWithCommas";
import Link from "next/link";
import BuyButton from "../../../common/buttons/BuyButton";
const Progress = ({ t, apiData }) => {
    const progress = t('home:progress', {}, { returnObjects: true });
    const line_content = (text_1, text_2, text_3) => {
        return <div className={'line_item'}>
            {text_1}<span style={{ marginLeft: '10px' }} className={'bold'}> $GEGG</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="19" viewBox="0 0 23 19" fill="none">
                <path d="M12.9639 1.54419L20.6865 9.81226L12.4185 17.5349" stroke="#191919" strokeWidth="2"/>
                <path d="M21.0164 9.82486L1.02803 9.14307" stroke="#191919" strokeWidth="2"/>
            </svg>
            <span className={'bold'} style={{ marginRight: '10px' }}>15% {text_2} </span> {text_3}
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="19" viewBox="0 0 23 19" fill="none">
                <path d="M12.9639 1.54419L20.6865 9.81226L12.4185 17.5349" stroke="#191919" strokeWidth="2"/>
                <path d="M21.0164 9.82486L1.02803 9.14307" stroke="#191919" strokeWidth="2"/>
            </svg>
            {text_1}<span style={{ marginLeft: '10px' }} className={'bold'}> $GEGG</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="19" viewBox="0 0 23 19" fill="none">
                <path d="M12.9639 1.54419L20.6865 9.81226L12.4185 17.5349" stroke="#191919" strokeWidth="2"/>
                <path d="M21.0164 9.82486L1.02803 9.14307" stroke="#191919" strokeWidth="2"/>
            </svg>
            <span className={'bold'} style={{ marginRight: '10px' }}>15% {text_2} </span> {text_3}
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="19" viewBox="0 0 23 19" fill="none">
                <path d="M12.9639 1.54419L20.6865 9.81226L12.4185 17.5349" stroke="#191919" strokeWidth="2"/>
                <path d="M21.0164 9.82486L1.02803 9.14307" stroke="#191919" strokeWidth="2"/>
            </svg>
            {text_1}<span style={{ marginLeft: '10px' }} className={'bold'}> $GEGG</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="19" viewBox="0 0 23 19" fill="none">
                <path d="M12.9639 1.54419L20.6865 9.81226L12.4185 17.5349" stroke="#191919" strokeWidth="2"/>
                <path d="M21.0164 9.82486L1.02803 9.14307" stroke="#191919" strokeWidth="2"/>
            </svg>
            <span className={'bold'} style={{ marginRight: '10px' }}>15% {text_2} </span> {text_3}
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="19" viewBox="0 0 23 19" fill="none">
                <path d="M12.9639 1.54419L20.6865 9.81226L12.4185 17.5349" stroke="#191919" strokeWidth="2"/>
                <path d="M21.0164 9.82486L1.02803 9.14307" stroke="#191919" strokeWidth="2"/>
            </svg>
        </div>;
    };
    return (<section className='progress_section'>
            <div className="container">
                <div className="progress_section_wrapper">
                    <div className="progress_stage" data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={200}>
                        <div className="progress_stage_stage">
                            <div className='stage_data'>
                                <span className='stage_data_value'>{apiData?.stage_name}</span>
                                <span className='stage_data_text'>{progress.stage.stage_text_1}</span>
                                <span className='stage_data_text'>{progress.stage.stage_text_2}</span>
                            </div>
                        </div>
                        <div className="progress_stage_raised">
                            <div className='raised_data'>
                                <span className='raised_data_title'>{progress.stage.raise_title}</span>
                                <span className='raised_data_value'>${numberWithCommas(Number(apiData?.usd_raised).toFixed(0) || 0)}</span>
                            </div>
                        </div>
                    </div>
                    <div className='progressbar_titles' data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={400}>
                        <div className="progressbar_titles_sold">{progress.bar.pct_sold}</div>
                        <div className="progressbar_titles_remaining">{progress.bar.remaining}</div>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={400} className="progress_progress" style={{ "--prog": `${apiData?.percent_tokens}%` }}>
                        <div className="pct_sold">
                            <div className={`pct_sold_value ${apiData?.percent_tokens < 30 ? 'wh' : ''}`}>{Number(apiData?.percent_tokens).toFixed(2)}%
                            </div>
                        </div>
                        <div className="remaining">
                            <div className="remaining_value">{numberWithCommasNull(Number(apiData?.tokens_left_by_stage).toFixed(2))}
                                <span className={'remaining_value_title'}>$GEGG</span></div>
                        </div>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={600} className="progress_data">
                        <div className="progress_data_price progress_data_item">
                            <div className="title">{progress.data.price}</div>
                            <div className="value">{apiData?.current_token_price} USDT</div>
                        </div>
                        <div className="progress_data_sold progress_data_item">
                            <div className="title">{progress.data.sold}</div>
                            <div className="value">{numberWithCommasNull(Number(apiData?.tokens_sold).toFixed(2))}</div>
                        </div>
                        <div className="progress_data_next progress_data_item">
                            <div className="title">{progress.data.next_stage}</div>
                            <div className="value">{apiData?.next_token_price} USDT</div>
                        </div>
                    </div>

                    <Link data-aos="fade-in" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={600} className='progress_cta' prefetch={false} href={process.env.NEXT_PUBLIC_APP_URL + '/sign-up'}>
                        <BuyButton text={progress.cta}/>
                    </Link>
                    <div className="progress_ccy" data-aos="fade-in" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={600}>
                        <img width={212} height={24} src='/img/icons/ccy.svg' alt='$GEGG currencies'/>
                    </div>
                </div>
                <div className="lines">
                    <div className="blur"/>
                    <div className="line one">
                        {line_content(progress.line_1, progress.line_2, progress.line_3)}
                    </div>
                    <div className="line two">
                        {line_content(progress.line_1, progress.line_2, progress.line_3)}
                    </div>
                    <div className="image">
                        <picture>
                            <source media="(min-width: 577px)" srcSet={`/img/home/progress/progress_bird.webp`} type="image/webp"/>
                            <source media="(max-width: 576px)" srcSet={`/img/home/progress/progress_bird_small.webp`} type="image/webp"/>
                            <source media="(min-width: 577px)" srcSet={`/img/home/progress/progress_bird.png`}/>
                            <source media="(max-width: 576px)" srcSet={`/img/home/progress/progress_bird_small.png`}/>
                            <img src={`/img/home/progress/progress_bird.png`} alt="GOODEGGS" width={373} height={464} className='media_banner_main'/>
                        </picture>
                    </div>
                </div>
            </div>

        </section>);
};
export default Progress;

    async function __Next_Translate__getStaticProps__19228a81b23__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/Progress/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19228a81b23__ as getStaticProps }
  