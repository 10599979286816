
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
const Why = ({ t }) => {
    const why = t('home:why', {}, { returnObjects: true });
    return (<section className='why_section' id={'features'}>
            <div className="container">
                <div className="why_section_wrapper">
                    <div className="why_media" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={200}>
                        <h2 className='why_media_title'>{why.title_main}</h2>
                        <picture>
                            <source media="(min-width: 577px)" srcSet={`/img/home/why/why_media.webp`} type="image/webp"/>
                            <source media="(max-width: 576px)" srcSet={`/img/home/why/why_media_small.webp`} type="image/webp"/>
                            <source media="(min-width: 577px)" srcSet={`/img/home/why/why_media.png`}/>
                            <source media="(max-width: 576px)" srcSet={`/img/home/why/why_media_small.png`}/>
                            <img src={`/img/home/why/why_media.png`} alt="GOODEGGS" width={1318} height={1329} className='why_media_img'/>
                        </picture>
                    </div>
                    <div className="why_description">
                        <h3 className='why_description_title' data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={200}>
                            <div className='title_img'>
                                <picture>
                                    <source srcSet={`/img/home/why/title_bird.webp`} type="image/webp"/>
                                    <source srcSet={`/img/home/why/title_bird.png`}/>
                                    <img src={`/img/home/why/title_bird.png`} alt="GOODEGGS" width={190} height={247} className=''/>
                                </picture>
                            </div>
                            <span>{why.title_1}</span>
                            <span className={'yellow'}>{why.title_2}</span>
                        </h3>
                        <ul className='why_description_list'>
                            {why.items.map((el, i) => {
            return <li data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={100 * (i + 3)} key={el.title} className='list_item'>
                                        <h3 className='list_item_title'>{el.title}</h3>
                                        <p className='list_item_description'>{el.text}</p>
                                    </li>;
        })}
                        </ul>
                    </div>
                </div>
            </div>
        </section>);
};
export default Why;

    async function __Next_Translate__getStaticProps__19228a81b31__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/Why/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19228a81b31__ as getStaticProps }
  