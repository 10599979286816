
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
const Join = ({ t }) => {
    const join = t('home:join', {}, { returnObjects: true });
    return (<section className='join'>
            <div className="birds_wrapper">
                <div className="join_l_wrapper">
                    <picture>
                        <source media="(min-width: 577px)" srcSet={`/img/home/join/join_l.webp`} type="image/webp"/>
                        <source media="(max-width: 576px)" srcSet={`/img/home/join/join_l_small.webp`} type="image/webp"/>
                        <source media="(min-width: 577px)" srcSet={`/img/home/join/join_l.png`}/>
                        <source media="(max-width: 576px)" srcSet={`/img/home/join/join_l_small.png`}/>
                        <img src={`/img/home/join/join_l.png`} alt="GOODEGGS" width={622} height={688} className='join_l'/>
                    </picture>
                </div>
                <div className="join_r_wrapper">
                    <picture>
                        <source media="(min-width: 577px)" srcSet={`/img/home/join/join_r.webp`} type="image/webp"/>
                        <source media="(max-width: 576px)" srcSet={`/img/home/join/join_r_small.webp`} type="image/webp"/>
                        <source media="(min-width: 577px)" srcSet={`/img/home/join/join_r.png`}/>
                        <source media="(max-width: 576px)" srcSet={`/img/home/join/join_r_small.png`}/>
                        <img src={`/img/home/join/join_r.png`} alt="GOODEGGS" width={1083} height={1683} className='join_r'/>
                    </picture>
                </div>
            </div>
            <div className="container">
                <div className={'join_title_wrapper'}>
                    <h2 className={'join_title'}>{join.title}</h2>
                </div>
                <div className={'join_screens'}>
                    <picture>
                        <source media="(min-width: 577px)" srcSet={`/img/home/join/join_chat.webp`} type="image/webp"/>
                        <source media="(max-width: 576px)" srcSet={`/img/home/join/join_chat_small.webp`} type="image/webp"/>
                        <source media="(min-width: 577px)" srcSet={`/img/home/join/join_chat.png`}/>
                        <source media="(max-width: 576px)" srcSet={`/img/home/join/join_chat_small.png`}/>
                        <img src={`/img/home/join/join_chat.png`} alt="GOODEGGS" width={944} height={1405} className='join_chat' data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={100}/>
                    </picture>
                    <picture>
                        <source media="(min-width: 577px)" srcSet={`/img/home/join/join_splash.webp`} type="image/webp"/>
                        <source media="(max-width: 576px)" srcSet={`/img/home/join/join_splash_small.webp`} type="image/webp"/>
                        <source media="(min-width: 577px)" srcSet={`/img/home/join/join_splash.png`}/>
                        <source media="(max-width: 576px)" srcSet={`/img/home/join/join_splash_small.png`}/>
                        <img src={`/img/home/join/join_splash.png`} alt="GOODEGGS" width={800} height={1213} className='join_splash' data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={100}/>
                    </picture>
                </div>
            </div>
        </section>);
};
export default Join;

    async function __Next_Translate__getStaticProps__19228a81bd0__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/Join/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19228a81bd0__ as getStaticProps }
  