
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import AppStoreButton from "../../../common/buttons/AppStoreButton";
import PlayMarketButton from "../../../common/buttons/PlayMarketButton";
import Link from "next/link";
import BuyButton from "../../../common/buttons/BuyButton";
import BuyButtonTransparent from "../../../common/buttons/BuyButtonTransparent";
const JoinApp = ({ t }) => {
    const joinapp = t('home:joinapp', {}, { returnObjects: true });
    return (<section className={'join_app'} data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={200}>
            <div className="container">
                <div className="join_app_wrapper">
                    <div className={'join_app_media'}>
                        <picture>
                            <source media="(min-width: 577px)" srcSet={`/img/home/joinapp/join.webp`} type="image/webp"/>
                            <source media="(max-width: 576px)" srcSet={`/img/home/joinapp/join_small.webp`} type="image/webp"/>
                            <source media="(min-width: 577px)" srcSet={`/img/home/joinapp/join.png`}/>
                            <source media="(max-width: 576px)" srcSet={`/img/home/joinapp/join_small.png`}/>
                            <img src={`/img/home/joinapp/join.png`} alt="GOODEGGS virtual dating" width={615} height={535} className=''/>
                        </picture>
                    </div>
                    <div className={'join_app_info'}>
                        <h2 className={'join_app_info_title'}>
                            <span>{joinapp.title_1}</span>
                            <span>{joinapp.title_2}</span>
                            <span>{joinapp.title_3}</span>
                            <span>{joinapp.title_4}</span>
                        </h2>

                        <div className={'join_app_info_cta'}>
                            {/*<AppStoreButton/>*/}
                            {/*<PlayMarketButton/>*/}
                            <Link className='roadmap_cta' prefetch={false} href={process.env.NEXT_PUBLIC_APP_URL + '/sign-up'}>
                                <BuyButtonTransparent text={joinapp.join_cta}/>
                            </Link>
                            <svg className={'sex'} width="65" height="32" viewBox="0 0 65 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M51.136 32V26.816H45.472V24.56H51.136V20.336C49.504 20.112 48.032 19.536 46.72 18.608C45.408 17.68 44.368 16.496 43.6 15.056C42.832 13.616 42.448 12.064 42.448 10.4C42.448 8.608 42.896 6.976 43.792 5.504C44.72 4 45.936 2.8 47.44 1.904C48.976 1.008 50.64 0.559999 52.432 0.559999C54.256 0.559999 55.92 1.008 57.424 1.904C58.96 2.8 60.176 4 61.072 5.504C61.968 6.976 62.416 8.608 62.416 10.4C62.416 12.032 62.032 13.568 61.264 15.008C60.496 16.448 59.456 17.648 58.144 18.608C56.864 19.536 55.392 20.112 53.728 20.336V24.56H59.056V26.816H53.728V32H51.136ZM52.432 17.984C53.808 17.984 55.072 17.648 56.224 16.976C57.376 16.272 58.288 15.344 58.96 14.192C59.664 13.04 60.016 11.776 60.016 10.4C60.016 9.056 59.664 7.824 58.96 6.704C58.288 5.584 57.376 4.688 56.224 4.016C55.072 3.312 53.808 2.96 52.432 2.96C51.056 2.96 49.792 3.312 48.64 4.016C47.488 4.688 46.56 5.584 45.856 6.704C45.184 7.824 44.848 9.056 44.848 10.4C44.848 11.776 45.184 13.04 45.856 14.192C46.56 15.344 47.488 16.272 48.64 16.976C49.792 17.648 51.056 17.984 52.432 17.984Z" fill="white"/>
                                <path d="M12.432 27C10.64 27 8.976 26.552 7.44 25.656C5.936 24.76 4.72 23.56 3.792 22.056C2.896 20.52 2.448 18.84 2.448 17.016C2.448 15.224 2.896 13.592 3.792 12.12C4.72 10.616 5.936 9.416 7.44 8.52C8.976 7.624 10.64 7.176 12.432 7.176C13.52 7.176 14.56 7.352 15.552 7.704C16.544 8.024 17.488 8.504 18.384 9.144L22.848 4.152L19.056 2.28L27.12 1.368L27.168 9.48L24.816 5.88L20.256 10.968C20.992 11.864 21.536 12.824 21.888 13.848C22.24 14.84 22.416 15.896 22.416 17.016C22.416 18.84 21.968 20.52 21.072 22.056C20.176 23.56 18.96 24.76 17.424 25.656C15.92 26.552 14.256 27 12.432 27ZM12.432 24.6C13.808 24.6 15.072 24.264 16.224 23.592C17.376 22.888 18.288 21.96 18.96 20.808C19.664 19.656 20.016 18.392 20.016 17.016C20.016 15.672 19.664 14.44 18.96 13.32C18.288 12.168 17.376 11.256 16.224 10.584C15.072 9.912 13.808 9.576 12.432 9.576C11.056 9.576 9.792 9.912 8.64 10.584C7.488 11.256 6.56 12.168 5.856 13.32C5.184 14.44 4.848 15.672 4.848 17.016C4.848 18.392 5.184 19.656 5.856 20.808C6.56 21.96 7.488 22.888 8.64 23.592C9.792 24.264 11.056 24.6 12.432 24.6Z" fill="white"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </section>);
};
export default JoinApp;

    async function __Next_Translate__getStaticProps__19228a81bfb__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/JoinApp/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19228a81bfb__ as getStaticProps }
  