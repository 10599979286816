
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
const Team = ({ t }) => {
    const team = t('home:team', {}, { returnObjects: true });
    const TeamItem = ({ item, i }) => {
        return <li className={'team_item'} data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={200 * (i + 1)}>
            <picture>
                <source media="(min-width: 577px)" srcSet={`/img/home/team/team_${item.icon}.webp`} type="image/webp"/>
                <source media="(min-width: 577px)" srcSet={`/img/home/team/team_${item.icon}.png`}/>
                <img src={`/img/home/team/team_${item.icon}.png`} alt="GOODEGGS virtual dating" width={290} height={522} className='team_item_img'/>
            </picture>
            <div className='team_item_info'>
                <h3 className='name'>{item.name}</h3>
                <p className='position'>{item.position}</p>
            </div>
        </li>;
    };
    return (<section className='team' id={'team'}>
            <div className="container">
                <div className="team_wrapper">

                    <ul className={'team_items'}>
                        <li className={'team_item item_title'}>
                            <h2 className={'team_title'}>
                                <span>{team.title_1}</span>
                                <span>{team.title_2}</span>
                            </h2>
                        </li>
                        {team.items.map((item, i) => {
            return <TeamItem i={i} key={item.icon} item={item}/>;
        })}
                    </ul>
                </div>
            </div>
        </section>);
};
export default Team;

    async function __Next_Translate__getStaticProps__19228a81bdc__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/Team/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19228a81bdc__ as getStaticProps }
  