
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import Link from "next/link";
import BuyButton from "../../../common/buttons/BuyButton";
const Banner = ({ t }) => {
    const banner = t('home:banner', {}, { returnObjects: true });
    return (<section className='banner_section'>
            <div className="container">
                <div className="banner_section_wrapper">
                    <div className='main_banner'>
                        <p className='main_banner_promo' data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={200}>
                            <span>{banner.promo_1}</span><span style={{ color: '#F6FC5A' }}>'GOLDENEGG'</span><span>{banner.promo_2}</span><span style={{ fontFamily: 'Plex-500, san-serif' }}>15%</span><span style={{ fontFamily: 'Plex-500, san-serif' }}>{banner.promo_3}</span>
                        </p>
                        <h1 className='main_banner_title' data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={400}>{banner.title}</h1>
                        <p className='main_banner_subtitle' data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={600}>{banner.sub_title}</p>
                        <div className={'main_banner_cta'}>
                            <Link className='buy_now' prefetch={false} href={process.env.NEXT_PUBLIC_APP_URL + '/sign-up'} data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={800}>
                                <BuyButton text={banner.cta}/>
                            </Link>
                            <Link data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={1000} className={'wp'} target={'_blank'} rel={'noopener'} href={'https://whitepaper.goodeggtoken.com/'}>{banner.whitepaper}</Link>
                        </div>


                    </div>
                    <div className='media_banner'>
                        <div className='media_banner_wrapper'>
                            <picture>
                                <source media="(min-width: 577px)" srcSet={`/img/home/banner/banner_birds.webp`} type="image/webp"/>
                                <source media="(max-width: 576px)" srcSet={`/img/home/banner/banner_birds_small.webp`} type="image/webp"/>
                                <source media="(min-width: 577px)" srcSet={`/img/home/banner/banner_birds.png`}/>
                                <source media="(max-width: 576px)" srcSet={`/img/home/banner/banner_birds_small.png`}/>
                                <img src={`/img/home/banner/banner_birds.png`} alt="GOODEGGS" width={640} height={709} className='media_banner_main' data-aos="fade-in" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={800}/>
                            </picture>
                        </div>
                    </div>
                </div>
            </div>
        </section>);
};
export default Banner;

    async function __Next_Translate__getStaticProps__19228a81b15__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/Banner/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19228a81b15__ as getStaticProps }
  