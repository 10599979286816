
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import Link from "next/link";
import BuyButton from "../../../common/buttons/BuyButton";
const SocialFi = ({ t }) => {
    const social_fi = t('home:social_fi', {}, { returnObjects: true });
    return (<section className={'social_fi'}>
            <div className="container">
                <div className="social_fi_wrapper">
                    <h2 data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={200} className='social_fi_title'>{social_fi.title}</h2>
                    <div className="social_fi_content">
                        <div className="social_fi_info">
                            <p data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={400} className='social_fi_info_text'>{social_fi.description}</p>
                            <div className={'social_fi_info_cta'}>
                                <Link data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={600} className='buy_now' prefetch={false} href={process.env.NEXT_PUBLIC_APP_URL + '/sign-up'}>
                                    <BuyButton text={social_fi.cta}/>
                                </Link>
                                <Link data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={800} className={'wp'} href={process.env.NEXT_PUBLIC_APP_URL + '/sign-up'}>{social_fi.whitepaper}</Link>
                            </div>
                        </div>
                        <div className={'social_fi_empty'}>

                        </div>
                    </div>

                </div>
                <div className="social_fi_media">
                    <picture>
                        <source media="(min-width: 577px)" srcSet={`/img/home/social_fi/sc_one.webp`} type="image/webp"/>
                        <source media="(max-width: 576px)" srcSet={`/img/home/social_fi/sc_one_small.webp`} type="image/webp"/>
                        <source media="(min-width: 577px)" srcSet={`/img/home/social_fi/sc_one.png`}/>
                        <source media="(max-width: 576px)" srcSet={`/img/home/social_fi/sc_one_small.png`}/>
                        <img data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={400} src={`/img/home/social_fi/sc_one.png`} alt="GOODEGGS" width={495} height={372} className='why_media_img'/>
                    </picture>
                    <picture>
                        <source media="(min-width: 577px)" srcSet={`/img/home/social_fi/sc_two.webp`} type="image/webp"/>
                        <source media="(max-width: 576px)" srcSet={`/img/home/social_fi/sc_two_small.webp`} type="image/webp"/>
                        <source media="(min-width: 577px)" srcSet={`/img/home/social_fi/sc_two.png`}/>
                        <source media="(max-width: 576px)" srcSet={`/img/home/social_fi/sc_two_small.png`}/>
                        <img data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={600} src={`/img/home/social_fi/sc_two.png`} alt="GOODEGGS" width={332} height={372} className='why_media_img'/>
                    </picture>
                </div>
            </div>
        </section>);
};
export default SocialFi;

    async function __Next_Translate__getStaticProps__19228a81b3e__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/SocialFi/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19228a81b3e__ as getStaticProps }
  